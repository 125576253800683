import React from 'react'
import PropTypes from 'prop-types'
import Carousel from 'nuka-carousel'
import { useMediaQuery } from 'react-responsive'

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 901 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 701, maxWidth: 900 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 700 })
  return isMobile ? children : null
}

const Testimonials = ({ testimonials }) => (


  <section className='container mb-6 section'>

    <div className='column is-8 is-offset-2 has-text-centered mb-6'>
      <h2 className='has-text-weight-bold is-size-5'>Here's what other ambitious startups and founders have gained:</h2>
    </div>

    <div className='columns is-variable is-8'>

      <Desktop>
        <Carousel
        slidesToShow={3}
        slidesToScroll={3}
        transitionMode="fade"
        renderCenterLeftControls={({ previousSlide }) => (null)}
        renderCenterRightControls={({ nextSlide }) => (null)}
        >
        {testimonials.map((testimonial, id) => (
          <div className='column'>
            <article className='message px-4 py-4' key={id}>
              <div className='message-body'>
                <img className='mb-5' width='30%' src={testimonial.logo} alt={testimonial.authorCo} />
                <p className='is-size-4 mb-5'>{testimonial.quote}</p>
                <cite>
                  <p><span className='has-text-weight-bold'>{testimonial.authorName}</span>
                  <br/> 
                  <span>{testimonial.authorTitle} <a title={testimonial.authorCo} target='_blank' href={testimonial.authorURL}>{testimonial.authorCo}</a></span></p>
                </cite>
              </div>
            </article>
          </div>
        ))}
        </Carousel>
      </Desktop>

      <Tablet>
        <Carousel
          slidesToShow={2}
          slidesToScroll={2}
          transitionMode="fade"
          renderCenterLeftControls={({ previousSlide }) => (null)}
          renderCenterRightControls={({ nextSlide }) => (null)}
        >
          {testimonials.map((testimonial, id) => (
            <div className='column'>
              <article className='message px-4 py-4' key={id}>
                <div className='message-body'>
                  <img className='mb-5' width='30%' src={testimonial.logo} alt={testimonial.authorCo} />
                  <p className='is-size-4 mb-5'>{testimonial.quote}</p>
                  <cite>
                    <p><span className='has-text-weight-bold'>{testimonial.authorName}</span>
                    <br/> 
                    <span>{testimonial.authorTitle} <a title={testimonial.authorCo} target='_blank' href={testimonial.authorURL}>{testimonial.authorCo}</a></span></p>
                  </cite>
                </div>
              </article>
            </div>
          ))}
        </Carousel>
      </Tablet>

      <Mobile>
        <Carousel
          slidesToShow={1}
          slidesToScroll={1}
          transitionMode="fade"
          renderCenterLeftControls={({ previousSlide }) => (null)}
          renderCenterRightControls={({ nextSlide }) => (null)}
        >
          {testimonials.map((testimonial, id) => (
            <div className='column'>
              <article className='message px-4 py-4' key={id}>
                <div className='message-body'>
                  <img className='mb-5' width='30%' src={testimonial.logo} alt={testimonial.authorCo} />
                  <p className='is-size-4 mb-5'>{testimonial.quote}</p>
                  <cite>
                    <p><span className='has-text-weight-bold'>{testimonial.authorName}</span>
                    <br/> 
                    <span>{testimonial.authorTitle} <a title={testimonial.authorCo} target='_blank' href={testimonial.authorURL}>{testimonial.authorCo}</a></span></p>
                  </cite>
                </div>
              </article>
            </div>
          ))}
        </Carousel>
      </Mobile>
    </div>
  </section>
)

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.string,
      quote: PropTypes.string,
      authorName: PropTypes.string,
      authorTitle: PropTypes.string,
      authorCo: PropTypes.string,
      authorURL: PropTypes.string,
    })
  ),
}

export default Testimonials
