import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Name is Required!'),
  email: Yup.string()
    .email('Please enter a Valid Email')
    .required('Email is Required'),
  url: Yup.string()
    .url('Please enter a Valid URL')
    .required('URL is Required'),
})

export default validationSchema
